



































































































































































import {
  BPagination,
  BSpinner,
  BFormInput,
  BInputGroup,
  BForm,
  BFormGroup,
  BFormFile,
  BButton,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import {
  RapportActiviteConfig,
} from "@/api/models/rapportActivites/rapportActiviteTemplate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { VueSelect } from "vue-select";
import graphList from '../graphList';
import { graphType } from "@/api/models/enums/graphType";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BSpinner,
    DeleteIcon,
    BForm,
    BFormInput,
    BFormGroup,
    SearchableVueSelect,
    BFormFile,
    BButton,
    VueSelect,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
  },
})
export default class UpdateModalRapportsActivitesTemplates extends Vue {
  @Prop({ required: true }) selectedConfig? : RapportActiviteConfig | null = null;

  graphs= graphList;
  refreshIndex = 0;
  listLegendPositionOptions = [
    { id: 1, text: "A gauche", value: "left" },
    { id: 2, text: "A droite", value: "right" },
    { id: 3, text: "En haut", value: "top" },
    { id: 4, text: "En bas", value: "bottom" },
  ];

  get graphRefIsTypeDonutOrPie(){
    return this.selectedConfig?.graphRef && ( this.graphs.find((x) => x.ref == this.selectedConfig?.graphRef)?.graphType == graphType.DONUT || this.graphs.find((x) => x.ref == this.selectedConfig?.graphRef)?.graphType == graphType.PIE) ;
  }

  get graphRefMaxCategoryIsAllowed() {
    return this.selectedConfig?.graphRef && (this.graphs.find((x) => x.ref == this.selectedConfig?.graphRef)?.allowMaxCategory === true);
  }

  get graphrefIsTypeNumber(){
    return this.selectedConfig != null && this.selectedConfig.graphRef && this.graphs.find((x) => x.ref == this.selectedConfig!.graphRef)?.typeNumber;
  }

  get graphrefAllowCustom(){
    return this.selectedConfig != null && 
      this.selectedConfig.graphRef && 
      this.graphs.find((x) => x.ref == this.selectedConfig!.graphRef)?.customSize;
  }

  widthChangedOnUpdate(val: any) {
    var res = this.graphs.find((x) => x.ref == this.selectedConfig!.graphRef);

    switch (this.selectedConfig!.legendPosition) {
      case "bottom": {
        this.selectedConfig!.height = val * res?.ratioBottom!;
        break;
      }
      case "top": {
        this.selectedConfig!.height = val * res?.ratioTop!;
        break;
      }
      case "left": {
        this.selectedConfig!.height = val * res?.ratioLeft!;
        break;
      }
      case "right": {
        this.selectedConfig!.height = val * res?.ratioRight!;
        break;
      }
      default:
    }

    this.refreshIndex++;
    
  }

    async copyToClipBoard() {
    let charToCopy = this.selectedConfig!.hauteur?.toString().replace(".",",");
    await navigator.clipboard
      .writeText(charToCopy!.toString())
      .then(() => {
        successAlert.fire({
          title: "Copie des données",
          text: "Les données ont bien été copié dans le presse papier",
        });
      });
  }

  async updateConfig(configId: string) {
    await this.$http.ressifnet.rapportActiviteTemplates
      .updateConfig(
        this.$route.params.rapportActiviteTemplateId,
        this.selectedConfig!.id,
        this.selectedConfig
      )
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Modification des paramètres d'un graphique",
            text: "Modification effectuée avec succès",
          });
          this.selectedConfig = null;

          this.$emit("load-template");
        },
        (error: any) => {
          errorAlert.fire({
            text: error.message,
          });
        }
      );
  }
}
