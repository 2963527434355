






















































































































































































import {
  BPagination,
  BSpinner,
  BFormInput,
  BInputGroup,
  BForm,
  BFormGroup,
  BFormFile,
  BButton,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import {
  UpdateRapportActiviteConfigModel,
} from "@/api/models/rapportActivites/rapportActiviteTemplate";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { VueSelect } from "vue-select";
import graphList from "../graphList";
import { required } from "@validations";
import { graphType } from "@/api/models/enums/graphType";
import { BFormCheckboxGroup } from 'bootstrap-vue';
@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BSpinner,
    DeleteIcon,
    BForm,
    BFormInput,
    BFormGroup,
    SearchableVueSelect,
    BFormFile,
    BButton,
    VueSelect,
    BFormSelect,
    BFormSelectOption,
    BFormCheckboxGroup,
    BInputGroup,
  },
})
export default class CreateModalRapportsActivitesTemplates extends Vue {
  @Prop({ required: false }) selectedTemplate: any;
  
  required = required;
  graphs = graphList;
  graphsFiltered: any[] = JSON.parse(JSON.stringify(graphList));
  refreshIndex = 0;

  graphType = graphType;
  selectedTypes: any[] = [];

  listLegendPositionOptions = [
    { id: 1, text: "A gauche", value: "left" },
    { id: 2, text: "A droite", value: "right" },
    { id: 3, text: "En haut", value: "top" },
    { id: 4, text: "En bas", value: "bottom" }
  ];

  graphTypesOptions = [
    { text: "Camembert", value: graphType.PIE },
    { text: "Donut", value: graphType.DONUT },
    { text: "Histogramme/Barres", value: graphType.BAR },
    { text: "Carte de chaleur", value: graphType.HEATMAP },
    { text: "Lignes", value: graphType.LINE },
    { text: "Polaire", value: graphType.POLAR },
    { text: "Radar", value: graphType.RADAR },
    { text: "Barres radiales", value: graphType.RADIALBAR },
    { text: "Radiaux", value: graphType.RADIAL },
    { text: "Nuage de points", value: graphType.SCATTER },
    { text: "Nombre", value: graphType.NUMBER },
  ]

  mounted() {
    this.selectedTypes = [...this.graphTypesOptions.map(v => v.value)];
  }

  get graphRefIsTypeDonutOrPie(){
    return this.newConfig.graphRef && (this.graphs.find((x) => x.ref == this.newConfig.graphRef)?.graphType == graphType.DONUT || this.graphs.find((x) => x.ref == this.newConfig.graphRef)?.graphType == graphType.PIE) ;
  }

  get graphRefMaxCategoryIsAllowed() {
    return this.newConfig.graphRef && (this.graphs.find((x) => x.ref == this.newConfig.graphRef)?.allowMaxCategory === true);
  }

  get graphrefIsTypeNumber(){
    return this.newConfig.graphRef && this.graphs.find((x) => x.ref == this.newConfig.graphRef)?.typeNumber;
  }

  get graphrefAllowCustom(){
    return this.newConfig.graphRef && this.graphs.find((x) => x.ref == this.newConfig.graphRef)?.customSize;
  }

  newConfig: UpdateRapportActiviteConfigModel = {
    templateId: this.$route.params.rapportActiviteTemplateId,
  };

  widthChanged(newConfig: any, val: any) {
    var res = this.graphs.find((x) => x.ref == newConfig.graphRef);

    switch (newConfig.legendPosition) {
      case "bottom": {
        newConfig.height = val * res?.ratioBottom!;
        break;
      }
      case "top": {
        newConfig.height = val * res?.ratioTop!;
        break;
      }
      case "left": {
        newConfig.height = val * res?.ratioLeft!;
        break;
      }
      case "right": {
        newConfig.height = val * res?.ratioRight!;
        break;
      }
    }

    this.refreshIndex++;
  }

  get getGraphs() {
    return this.graphs.filter(
      (g) =>
        !this.selectedTemplate!.configs.some((c: any) => c.graphRef == g.ref)
    );
  }

  async copyToClipBoard() {
    let charToCopy = this.newConfig.height?.toString().replace(".",",");
    await navigator.clipboard
      .writeText(charToCopy!.toString())
      .then(() => {
        successAlert.fire({
          title: "Copie des données",
          text: "Les données ont bien été copié dans le presse papier",
        });
      });
  }

  async addConfig() {
    await this.$http.ressifnet.rapportActiviteTemplates
      .postConfig(this.$route.params.rapportActiviteTemplateId, this.newConfig)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Ajout d'un graphique",
            text: "Ajout effectué avec succès",
          });
          this.newConfig = {};

          this.$emit("load-template");
        },
        (error: any) => {
          errorAlert.fire({
            text: error.message,
          });
        }
      );
  }

  search(val?: any) {
    this.graphsFiltered = this.graphs
      .filter(
        (g) =>
          !this.selectedTemplate!.configs.some((c: any) => c.graphRef == g.ref)
      )
      if(this.selectedTypes.length > 0)
        this.graphsFiltered = this.graphsFiltered.filter(g => this.selectedTypes.includes(g.graphType?.valueOf()!))
      if(val)
        this.graphsFiltered = this.graphsFiltered.filter(
          (x) => 
            x.name.toLowerCase().includes(val.toLowerCase())
        );
  }
}
