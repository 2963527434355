
























































































































































import { BPagination, BSpinner, BFormInput, BInputGroup, BForm, BFormGroup, BFormFile, BButton, BFormSelect, BFormSelectOption } from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import {
  RapportActiviteConfig,
  RapportActiviteTemplate,
  UpdateRapportActiviteConfigModel,
  UpdateRapportActiviteTemplateModel,
} from "@/api/models/rapportActivites/rapportActiviteTemplate";
import { required } from "@validations";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { VueSelect } from "vue-select";
import UpdateModalRapportsActivitesTemplates from "./modals/Update.vue";
import CreateModalRapportsActivitesTemplates from "./modals/Create.vue";
import graphList from './graphList';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BSpinner,
    DeleteIcon,
    BForm,
    BFormInput,
    BFormGroup,
    SearchableVueSelect,
    BFormFile,
    BButton,
    VueSelect,
    BFormSelect,
    BFormSelectOption,
    BInputGroup,
    UpdateModalRapportsActivitesTemplates,
    CreateModalRapportsActivitesTemplates
  },
})
export default class DetailsRapportActiviteTemplate extends Vue {
  required = required;
  modalLoading: boolean = false;
  newGraphique: any;
  updateLoading : boolean = false;

  droitsEnum = DroitsEnum;

  listLegendPositionOptions = [
    { id: 1, text: "A gauche", value: "left" },
    { id: 2, text: "A droite", value: "right" },
    { id: 3, text: "En haut", value: "top" },
    { id: 4, text: "En bas", value: "bottom" },
  ];
  refreshIndex= 1;
  
  selectedTemplate: RapportActiviteTemplate | null = null;
  // listConfigs: PaginatedList<RapportActiviteConfig> = new PaginatedList<RapportActiviteConfig>();
  selectedConfig: RapportActiviteConfig | null = null;
  newConfig: UpdateRapportActiviteConfigModel = {
    templateId: this.$route.params.rapportActiviteTemplateId,
  };

  listChartes: any[] = [];
  graphs = graphList;


  fichierToUpload: File | null = null;

  currentPage = 1;
  pageSize = 20;

  
  graphName(graphRef: string) {
    return this.graphs.find((x) => x.ref == graphRef)?.name;
  }

  async created() {
    await this.loadTemplate([]);
    await this.loadCharteGraphique();
  }

  goDetails(configId: string) {
    // alert(configId)
    this.selectedConfig = JSON.parse(
      JSON.stringify(
        this.selectedTemplate!.configs.find((x) => x.id == configId)
      )
    );
    this.$bvModal.show('modal-update-config-template')
  }

  async loadTemplate(params: any) {
    await this.$http.ressifnet.rapportActiviteTemplates
      .getById(this.$route.params.rapportActiviteTemplateId)
      .then((response: RapportActiviteTemplate) => {
        this.selectedTemplate = response;
      });
  }


  async updateTemplate() {
    this.updateLoading = true;
    var model: UpdateRapportActiviteTemplateModel = {
      id: this.selectedTemplate!.id,
      libelle: this.selectedTemplate!.libelle,
      charteGraphiqueId: this.selectedTemplate!.charteGraphiqueId,
    };

    if (this.fichierToUpload != undefined) {
      model.fichierToUpload = this.fichierToUpload;
    }

    await this.$http.ressifnet.rapportActiviteTemplates
      .put(this.$route.params.rapportActiviteTemplateId, model)
      .then(
        async (response: string) => {
          this.updateLoading = false;
          successAlert.fire({
            title: "Modification du template",
            text: "Modification effectuée avec succès",
          });

          await this.loadTemplate({});
        },
        (error: any) => {
          errorAlert.fire({
            text: error.message,
          });
        }
      );
  }

  removeConfig(configId: string) {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir retirer ce graphique du template ?",
        {
          title: "Confirmation de modification",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          await this.confirmRemove(configId);
        } else {
          return;
        }
      });
  }

  async confirmRemove(configId: string) {
    await this.$http.ressifnet.rapportActiviteTemplates
      .removeConfig(this.$route.params.rapportActiviteTemplateId, configId, {
        id: configId,
        templateId: this.$route.params.rapportActiviteTemplateId,
      })
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Retrait d'un graphique du template",
            text: "Modification effectuée avec succès",
          });

          await this.loadTemplate({});
        },
        (error: any) => {
          errorAlert.fire({
            text: error.message,
          });
        }
      );
  }

  async loadCharteGraphique() {
    await this.$http.ressifnet.charteGraphique.paginatedList().then(
      (response: any) => {
        this.listChartes = response["items"];
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  async downloadFile(fichier: any) {
    if (!fichier.id) return;
    else {
      await this.$http.ressifnet.fichiers.downloadFile(fichier.id).then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
    }
  }
}
