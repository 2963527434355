export enum graphType {
    PIE = "pie",
    DONUT = "donut",
    BAR = "bar",
    HEATMAP = "heatmap",
    LINE = "line",
    POLAR = "polar",
    RADAR = "radar",
    RADIALBAR= "radialBar",
    RADIAL = "radial",
    SCATTER = "scatter",
    NUMBER = "number"
}